import React from "react"
import { graphql, PageProps } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import { useI18n, LocaleLink as Link } from "../../contexts/i18n"
import Layout from "../../layout"
import SEO from "../../components/SEO"
import { DangerousHTML } from "../../components/contentful/HTML"
import ModalPlayer from "../../components/ModalPlayer"
import { watchPath } from "../../../routes"
// @ts-ignore
import * as styles from "../movie-and-show.module.scss"

interface DataType {
  movie: Contentful.Movie
}

function MovieTemplate({ data }: PageProps<DataType>) {
  const { translate: t } = useI18n(`movieTemplate`)

  const {
    movie,
    movie: { flick },
  } = data

  const isWatchable = !!flick.vimeoId

  return (
    <Layout>
      <SEO {...movie.metadata} />

      <h1 className={styles.movieTitle}>{movie.title}</h1>
      <h2 className={styles.movieYear}>({movie.year})</h2>

      <div className={styles.movieGrid}>
        <GatsbyImage
          className={`${styles.moviePageImage} ${styles.vertical}`}
          alt={movie.title}
          image={movie.posterImageVertical.gatsbyImage}
        />
        <GatsbyImage
          className={`${styles.moviePageImage} ${styles.horizontal}`}
          alt={movie.title}
          image={movie.posterImageHorizontal.gatsbyImage}
        />

        <div className={styles.movieInfo}>
          <div className={styles.movieInfoButtonGroup}>
            {isWatchable && (
              <Link to={watchPath(movie.slug)} title="Watch" className="cta-link">
                {t`Watch`}
              </Link>
            )}

            {movie.trailerUrl && <ModalPlayer title={t`Trailer`} url={movie.trailerUrl} />}
            {movie.extraUrl && <ModalPlayer title={t`Extras`} url={movie.extraUrl} />}
          </div>
          <span className={styles.movieInfoYear}>{movie.year}</span>
          <span className={styles.movieInfoRating}>{movie.rating}</span>

          <h3 className={styles.movieInfoHeading}>{t`Cast`}</h3>
          <p>{(movie.cast || []).join(`, `)}</p>

          <h3 className={styles.movieInfoHeading}>{t`Category`}</h3>
          <p>{[movie.type, ...(movie.genre || [])].join(`, `)}</p>

          <h3 className={styles.movieInfoHeading}>{t`Synopsis`}</h3>
          <DangerousHTML>{movie.description}</DangerousHTML>
        </div>
      </div>
    </Layout>
  )
}

export default MovieTemplate

export const pageQuery = graphql`
  query ($slug: String!, $locale: String!) {
    movie: contentfulMovie(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      title
      year
      posterImageVertical {
        gatsbyImage: gatsbyImageData(width: 300)
      }
      posterImageHorizontal {
        gatsbyImage: gatsbyImageData(width: 600)
      }
      type
      genre
      rating
      cast
      description {
        childMarkdownRemark {
          html
        }
      }
      trailerUrl
      extraUrl
      flick {
        vimeoId
      }
      metadata {
        title
        description
        keywords
        image {
          url
        }
      }
      slug
    }
  }
`
